import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Floater from './components/Floater';
import Loader from './components/Loader';
import ScrollToTop from './components/ScrollToTop';

const Index = lazy(() => import('./pages/Index'));
const About = lazy(() => import('./pages/About'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const OurGallery = lazy(() => import('./pages/OurGallery'));
// const Collections = lazy(() => import("./pages/Collections"));
const Curtains = lazy(() => import('./pages/Curtains'));
const MeasureCurtains = lazy(() => import('./pages/MeasureCurtains'));
const Wallpapers = lazy(() => import('./pages/Wallpapers'));
const Mattress = lazy(() => import('./pages/Mattress'));
const Floorings = lazy(() => import('./pages/Floorings'));
const WoodenFlooring = lazy(() => import('./pages/WoodenFlooring'));
const VinylFlooring = lazy(() => import('./pages/VinylFlooring'));
const CarpetsRugs = lazy(() => import('./pages/CarpetsRugs'));
// const GlassFilm = lazy(() => import("./pages/GlassFilm"));
const SportsFlooring = lazy(() => import('./pages/SportsFlooring'));
const SPCFlooring = lazy(() => import('./pages/SPCFlooring'));
const WoodenDeckFlooring = lazy(() => import('./pages/WoodenDeckFlooring'));
const ArtificialGarden = lazy(() => import('./pages/ArtificialGarden'));
// const ArtificialGrass = lazy(() => import("./pages/ArtificialGrass"));
const WindowBlinds = lazy(() => import('./pages/WindowBlinds'));
const MeasureBlinds = lazy(() => import('./pages/MeasureBlinds'));
const Upholstery = lazy(() => import('./pages/Upholstery'));
const WallDeco = lazy(() => import('./pages/WallDeco'));
const AdLanding = lazy(() => import('./pages/AdLanding'));
const GiftCard = lazy(() => import('./pages/GiftCard'));
const CustomFurnishing = lazy(() => import('./pages/CustomFurnishing'));
const WhyUs = lazy(() => import('./pages/WhyUs'));
// const Career = lazy(() => import("./pages/Career"));
const NotFound = lazy(() => import('./pages/NotFound'));

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Floater />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Index />
            </Suspense>
          }
        />
        <Route
          exact
          path="/landing"
          element={
            <Suspense fallback={<Loader />}>
              <AdLanding />
            </Suspense>
          }
        />
        <Route
          exact
          path="/gift-card"
          element={
            <Suspense fallback={<Loader />}>
              <GiftCard />
            </Suspense>
          }
        />
        <Route
          exact
          path="/why-us"
          element={
            <Suspense fallback={<Loader />}>
              <WhyUs />
            </Suspense>
          }
        />
        <Route
          exact
          path="/our-works-gallery"
          element={
            <Suspense fallback={<Loader />}>
              <OurGallery />
            </Suspense>
          }
        />
        {/* <Route exact path="/our-collections" element={
					<Suspense fallback={<Loader />}>
						<Collections />
					</Suspense>
				} /> */}
        <Route
          exact
          path="/curtains-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <Curtains />
            </Suspense>
          }
        />
        <Route
          exact
          path="/how-to-measure-curtains"
          element={
            <Suspense fallback={<Loader />}>
              <MeasureCurtains />
            </Suspense>
          }
        />
        <Route
          exact
          path="/wallpaper-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <Wallpapers />
            </Suspense>
          }
        />
        <Route
          exact
          path="/upholstery-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <Upholstery />
            </Suspense>
          }
        />
        <Route
          exact
          path="/window-blinds-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <WindowBlinds />
            </Suspense>
          }
        />
        <Route
          exact
          path="/how-to-measure-window-blinds"
          element={
            <Suspense fallback={<Loader />}>
              <MeasureBlinds />
            </Suspense>
          }
        />
        <Route
          exact
          path="/flooring-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <Floorings />
            </Suspense>
          }
        />
        <Route
          exact
          path="/wooden-flooring-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <WoodenFlooring />
            </Suspense>
          }
        />
        <Route
          exact
          path="/carpets-rugs-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <CarpetsRugs />
            </Suspense>
          }
        />
        <Route
          exact
          path="/spc-flooring-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <SPCFlooring />
            </Suspense>
          }
        />
        <Route
          exact
          path="/wooden-deck-flooring-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <WoodenDeckFlooring />
            </Suspense>
          }
        />
        <Route
          exact
          path="/vinyl-flooring-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <VinylFlooring />
            </Suspense>
          }
        />
        <Route
          exact
          path="/sport-flooring-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <SportsFlooring />
            </Suspense>
          }
        />
        <Route
          exact
          path="/mattress-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <Mattress />
            </Suspense>
          }
        />
        <Route
          exact
          path="/artificial-garden-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <ArtificialGarden />
            </Suspense>
          }
        />
        {/* <Route exact path="/artificial-grass-shop-in-pune" element={
					<Suspense fallback={<Loader />}>
						<ArtificialGrass />
					</Suspense>
				} />
				<Route exact path="/glass-film-shop-in-pune" element={
					<Suspense fallback={<Loader />}>
						<GlassFilm />
					</Suspense>
				} /> */}
        <Route
          exact
          path="/customised-furnishing-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <CustomFurnishing />
            </Suspense>
          }
        />
        <Route
          exact
          path="/wall-deco-shop-in-pune"
          element={
            <Suspense fallback={<Loader />}>
              <WallDeco />
            </Suspense>
          }
        />
        <Route
          exact
          path="/about-us"
          element={
            <Suspense fallback={<Loader />}>
              <About />
            </Suspense>
          }
        />
        <Route
          exact
          path="/contact-us"
          element={
            <Suspense fallback={<Loader />}>
              <ContactUs />
            </Suspense>
          }
        />
        {/* <Route exact path="/career-with-us" element={
					<Suspense fallback={<Loader />}>
						<Career />
					</Suspense>
				} /> */}
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}
