import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import "./assets/css/slicknav.min.css";
import "./assets/css/nice-select.min.css";
import "./assets/css/leaflet.min.css";
import "./assets/css/justifiedGallery.min.css";
import "./assets/css/helper.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/linea.min.css";
import "./assets/css/pe-icon-7-stroke.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/custom.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
